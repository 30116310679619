import React, {useEffect} from "react"

import Layout from "../components/layout"
import styled from 'styled-components';

// import SEO from '../components/seo';

import gsap from 'gsap';

const TopSection = styled.div`
  height: 18rem;
  background-color: ${props => props.theme.colors.dark};
`;

const Main = styled.div`
  height: 50vh;
  text-align: center;
  padding-top: 15rem;
`

const Header = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`

const Info = styled.p`
  width: 40rem;
  margin: 0 auto;
  line-height: 2.8rem;
  letter-spacing: 1px;
`

const NotFoundPage = () => {

  useEffect(() => {
    gsap.to('.logo', {autoAlpha: 1});
  }, []);
  
  return (
  <Layout>
    {/* <SEO title="404: Not found" /> */}
    <TopSection></TopSection>
    <Main>
      <Header>PAGE NOT FOUND</Header>
      <Info>You just hit a route that doesn't exist... Use the navigation list above to navigate the site.</Info>
    </Main>
  </Layout>
)}

export default NotFoundPage
